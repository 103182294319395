import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import "./header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from '../../assets/logo.png';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Image from "react-bootstrap/Image";
import Nav from 'react-bootstrap/Nav';

const Header = ({ variant }) => {
    const [displayHeader, setDisplayHeader] = useState(variant === 'home');
    const location = useLocation()

    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY;
          const isVariantHome = variant === 'home';
    
          if (scrollPosition < 600 && isVariantHome) {
            setDisplayHeader(true);
          } else {
            setDisplayHeader(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, [variant]);

    return (
        <Navbar collapseOnSelect fixed="top" style={{
            backgroundColor: 'var(--color-dark)',
            display: displayHeader ? 'none' : '',
        }} data-bs-theme="dark" className='header nav-bar bg py-3' expand="lg">
            <Container>
                <Navbar.Brand href="#">
                    <Image className='logo' src={logo}
                        fluid />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                    <Nav className='justify-content-end' activeKey={location.pathname}>
                        <Nav.Item className='px-4'>
                            <Nav.Link href="/home" className={location.pathname === '/home' ? 'active' : ''}>
                                Home
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='px-4'>
                            <Nav.Link href="/inventory" className={location.pathname === '/inventory' ? 'active' : ''}>
                                Inventory
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='about-link px-4'>
                            <Nav.Link href="/about" className={location.pathname === '/about' ? 'active' : ''}>
                                About
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='px-4'>
                            <Nav.Link href="/contact" className={location.pathname === '/contact' ? 'active' : ''}>
                                Contact
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header