import React from 'react';
import { Header, HomeLanding } from '../../components';
import './home.css';

const Home = () => {
    return (
        <div className="home">
            <Header variant={'home'}/>
            Last Test
            <HomeLanding />
        </div>
    )
}

export default Home