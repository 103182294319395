import React from 'react';
import bg from "../../assets/background.png";
import "./home-landing.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/esm/Image';
import logo from '../../assets/logo.png'

const HomeLanding = () => {
  
    return (
      <div className='home-bg text-center' style={{ 
        backgroundImage: `url(${bg})`,
        transition: 'background-image 1s ease', 
      }}>
        <Container>
          <Col className='home-content'>
            <Stack className='justify-content-center d-flex' direction='horizontal'>
              <Nav.Link className='mx-lg-5 mx-md-4 mx-sm-3 mx-2' href='/home' >
                <h5>Home</h5>
              </Nav.Link>
              <Nav.Link className='mx-lg-5 mx-md-4 mx-sm-3 mx-2' href='/shop'>
                <h5>Shop</h5>
              </Nav.Link>
              <Nav.Link className='mx-lg-5 mx-md-4 mx-sm-3 mx-2' href='/about'>
                <h5>About</h5>
              </Nav.Link>
              <Nav.Link className='mx-lg-5 mx-md-4 mx-sm-3 mx-2' href='/contact'>
                <h5>Contact</h5>
              </Nav.Link>
            </Stack>
            <Row className="justify-content-center">
              <Image className='logo' src={logo} />
            </Row>
              <Button href='/shop' variant="outline-light">
                <h2 className='px-3 py-2'>Shop Now</h2>
              </Button>
          </Col>
        </Container>
      </div>
    )
  }
  
  export default HomeLanding